import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ui-elements/ui-elements/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { MenuBox } from '@components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "menubox"
    }}>{`MenuBox`}</h1>
    <h2 {...{
      "id": "properties"
    }}>{`Properties`}</h2>
    <Props of={MenuBox} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<MenuBox title=\"compare with other fans\" iconName=\"ball\" onPress={() => {}} />\n<MenuBox title=\"prediction\" subtitle=\"coming soon\" iconName=\"pitch\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      MenuBox,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <MenuBox title="compare with other fans" iconName="ball" onPress={() => {}} mdxType="MenuBox" />
    <MenuBox title="prediction" subtitle="coming soon" iconName="pitch" mdxType="MenuBox" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      